// cSpell:disable
import { Button, message, Typography } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhoneVolume } from "@fortawesome/free-solid-svg-icons";
import { LoginOutlined } from "@ant-design/icons";

import "./LogIn.css";
import { loginRequest } from "../../auth/authConfig";
import Footer from "../../components/footer/Footer";
// cSpell:enable

const LogIn = ({ instance, setIsError }) => {
  const handleLogIn = () => {
    instance.loginPopup(loginRequest).catch((err) => {
      console.log(err);
      message.error("Log in failed. Please refresh and try again.", 5);
      setIsError(true);
    });
  };

  return (
    <>
      <div className="container">
        <div className="wrapper">
          <Typography.Title level={1}>
            <FontAwesomeIcon icon={faPhoneVolume} />
            TE Supervisor
          </Typography.Title>
          <Button
            onClick={handleLogIn}
            className="sign-in-btn"
            icon={<LoginOutlined />}
          >
            Log in with WFE Microsoft account
          </Button>
          <Button
            type="link"
            href="https://blob.wftl.com.tw/adfblob/%E6%88%AA%E5%9C%96%202024-09-30%20%E4%B8%8A%E5%8D%8810.26.13%E6%8B%B7%E8%B2%9D.png"
          >
            Front Door Test
          </Button>
        </div>
      </div>
      <Footer bgColor="#ececec" />
    </>
  );
};

export default LogIn;
